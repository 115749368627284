import React from 'react';
import MarketingLayout from '../../components/layout/MarketingLayout';
import PageHeader from '../../components/layout/PageHeader';
import Button from '../../components/ui/Button';
import PageSection from '../../components/ui/PageSection';
import Row from '../../components/ui/Row';
import Spacing from '../../components/ui/Spacing';

const ContactSalesConfirmationPage: React.SFC = () => (
  <MarketingLayout title="Contact Sales">
    <Spacing bottom={5}>
      <PageHeader
        title="Thanks for your request!"
        description="Expect an email shortly. In the meantime, learn more about how our customers are benefiting from Slab."
      />
    </Spacing>
    <PageSection>
      <Spacing bottom={8}>
        <Row centered verticalCentered>
          <Button primary to="/customer-stories/">
            View Customer Stories
          </Button>
        </Row>
      </Spacing>
    </PageSection>
  </MarketingLayout>
);

export default ContactSalesConfirmationPage;
